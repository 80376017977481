import React from "react"
import { Form, Formik, Field } from "formik"
import Dialog from "@material-ui/core/Dialog"
import Icon from "./Icon"
import { GOOGLE_RECAPTCHA_SITE_KEY } from "../config"
import useFirebase from "../useFirebase"

const inputs = [
  {
    label: "Full Name",
    name: "name",
    type: "text",
    placeholder: "Jane Doe",
  },
  {
    label: "Email",
    name: "email",
    type: "email",
    placeholder: "you@example.com",
  },
  {
    label: "Message",
    name: "message",
    type: "textarea",
    placeholder: "How can we help you?",
  },
]

const SuccessDialog: React.FC<{
  open: boolean
  setOpen: (open: boolean) => void
}> = ({ open, setOpen }) => {
  function handleClose() {
    setOpen(false)
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="p-4 text-center">
        <div className="h-24 bg-gradient-to-r from-teal-800 to-teal-700 text-white w-24 rounded-full shadow mb-2 m-auto flex items-center justify-center">
          <Icon name="check" width={50} />
        </div>
        <h5 className="text-3xl font-bold mb-2 text-teal-700">Success!</h5>
        <p className="mb-3 max-w-sm">
          We have received your message and will get back to you as soon as
          possible.
        </p>
        <div>
          <button
            onClick={handleClose}
            className="border-gray-500 border rounded bg-white block w-full py-1 text-center hover:bg-gray-100"
          >
            CLOSE
          </button>
        </div>
      </div>
    </Dialog>
  )
}

declare var window: any;

const ContactForm: React.FC<{ initialContactMessage?: string }> = ({
  initialContactMessage='',
}) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focused, setFocused] = React.useState(false);
  function handleFocus() {
    if (!focused) {
      setFocused(true);
    }
  }
  const firebase = useFirebase();
  React.useEffect(() => {
    if (focused) {
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=${GOOGLE_RECAPTCHA_SITE_KEY}`;
      document.body.appendChild(script);
      return () => document.body.removeChild(script);
    }
  }, [focused])
  return (
    <>
      <Formik
        initialValues={{ name: "", email: "", message: initialContactMessage }}
        validate={values => {
          const errors: { [key: string]: string } = {}
          if (!values.name) {
            errors.name = "Please enter your full name";
          }
          if (!values.email) {
            errors.email = "Please enter a valid email";
          }
          if (!values.message) {
            errors.message = "Please enter a message";
          }
          return errors;
        }}
        onSubmit={(values, { setStatus, setSubmitting, resetForm }) => {
          setStatus(null)
          const contactUs = firebase.functions().httpsCallable("contactUs")
          window.grecaptcha.ready(function() {
            window.grecaptcha
              .execute(GOOGLE_RECAPTCHA_SITE_KEY, { action: "CONTACT_US" })
              .then((recaptchaToken: string) => {
                return contactUs({ ...values, recaptchaToken })
              })
              .then(() => {
                setDialogOpen(true)
                resetForm()
              })
              .catch(() => {
                setStatus(`An error occurred - please try again later.`)
                setSubmitting(false)
              })
          })
        }}
      >
        {({ isSubmitting, status }) => {
          return (
            <div className="shadow-xl bg-white p-4">
              <h5 className="font-bold text-xl">Send Us a Message</h5>
              <span className={`w-24 h-1 bg-teal-300 block mt-3 mb-4`} />
              <Form>
                {inputs.map(({ label, name, type, placeholder }) => {
                  return (
                    <Field name={name} key={name}>
                      {({ field, meta }) => {
                        const hasError = meta.touched && meta.error
                        return (
                          <div className="mb-3">
                            <label
                              htmlFor={name}
                              className="block text-sm font-medium leading-5 text-gray-700 mb-1"
                            >
                              {label}
                            </label>
                            {type === "textarea" ? (
                              <textarea
                                {...field}
                                onFocus={handleFocus}
                                className={`resize-none appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                                id={name}
                                placeholder={placeholder}
                                rows={5}
                              />
                            ) : (
                              <input
                                {...field}
                                onFocus={handleFocus}
                                className={`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                                id={name}
                                type={type}
                                placeholder={placeholder}
                              />
                            )}
                            {hasError && (
                              <div className="pl-3">
                                <span className="text-red-600 text-sm">
                                  {meta.error}
                                </span>
                              </div>
                            )}
                          </div>
                        )
                      }}
                    </Field>
                  )
                })}
                <div className="border-gray-300 border inline-block mb-4 px-2 py-1 bg-gray-100">
                  <div className="leading-none pb-1">
                    <span className="text-gray-700 text-xs">
                      This form is protected by reCAPTCHA
                    </span>
                  </div>
                  <div className="flex">
                    <div className="mr-2">
                      <a
                        href="https://policies.google.com/privacy"
                        className="text-gray-600 hover:underline text-12px block"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://policies.google.com/terms"
                        className="text-gray-600 hover:underline text-12px block"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms
                      </a>
                    </div>
                  </div>
                </div>
                {status && (
                  <div>
                    <span className="text-red-500">{status}</span>
                  </div>
                )}
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`${
                    isSubmitting ? "opacity-50 pointer-events-none " : ""
                  }block w-full sm:w-48 shadow bg-teal-600 hover:bg-teal-500 focus:shadow-outline focus:outline-none text-white py-2 rounded`}
                >
                  {isSubmitting ? "SUBMITTING..." : "SUBMIT"}
                </button>
              </Form>
            </div>
          )
        }}
      </Formik>
      <SuccessDialog open={dialogOpen} setOpen={setDialogOpen} />
    </>
  )
}

export default ContactForm;